import React from 'react';

import { HOME_PAGES, POPULATION } from 'utils/routes';

import { Container, Item } from './styles';

const MobileMenu = () => (
  <Container>
    {HOME_PAGES.map(
      ({ id, to, name, color }) =>
        id !== POPULATION && (
          <Item key={to} to={to} $color={color}>
            {name}
          </Item>
        )
    )}
  </Container>
);

export default MobileMenu;
