/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useLocation, Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { INDEX, POPULATION, ELDERLY, DEMENTIA, DOCTOR, SCENARIO_HOME } from 'utils/routes';
import LogoSign from 'assets/svg-icons/LogoSign';

import { DESKTOP_MENU } from './consts';
import MenuIcon from './MenuIcon';
import Menu from './Menu';
import PrivateMenu from './PrivateMenu';
import Dropdown from './Dropdown';
import { Container, Wrapper, DesktopWrap, MobileWrap, ItemWrap, Button, MenuBtn, menuBtnVariants } from './styles';

const Header = ({ isPrivate }) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isIndex = useMatch(INDEX);
  const isIndexItem = useMemo(() => [POPULATION, ELDERLY, DEMENTIA, DOCTOR, SCENARIO_HOME].includes(pathname), [pathname]);

  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  useEffect(
    () => () => {
      setIsOpen(false);
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  return (
    <Container $isPrivate={isPrivate}>
      <Wrapper>
        {isPrivate ? (
          <PrivateMenu />
        ) : (
          <>
            <DesktopWrap>
              {DESKTOP_MENU.map((side, index) => (
                <ItemWrap key={index} $count={side.length} $index={index}>
                  {side.map(
                    ({ id, label, path, isDropdown, items }) =>
                      (index === 0 && (
                        <Link key={id} to={INDEX} aria-label="Main Page">
                          <LogoSign />
                        </Link>
                      )) ||
                      (isDropdown && <Dropdown key={id} label={label} items={items} />) || (
                        <Button key={id} to={path} $isIndex={isIndex || isIndexItem}>
                          {label}
                        </Button>
                      )
                  )}
                </ItemWrap>
              ))}
            </DesktopWrap>
            <MobileWrap>
              <Link to={INDEX} aria-label="Main Page">
                <LogoSign />
              </Link>
              <MenuBtn
                type="button"
                aria-label="Menu"
                onClick={handleClick}
                initial="close"
                animate={isOpen ? 'open' : 'close'}
                variants={menuBtnVariants}
              >
                <MenuIcon />
              </MenuBtn>
            </MobileWrap>
          </>
        )}
      </Wrapper>
      <Menu isOpen={isOpen} />
    </Container>
  );
};

Header.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
};

export default Header;
