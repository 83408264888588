import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSeenAnimation } from 'store/application';
import { HOME_PAGES, POPULATION } from 'utils/routes';

import { Container, Wrapper, Item } from './styles';

const DesktopMenu = ({ isOpened, onComplete }) => {
  const isSeenAnimation = useSeenAnimation();
  const onAnimationComplete = useCallback(
    ({ height }) => onComplete(isSeenAnimation ? false : height === 0),
    [isSeenAnimation, onComplete]
  );

  return (
    <Container $isOpened={isOpened} $defaultOpened={isSeenAnimation} onAnimationComplete={onAnimationComplete}>
      <Wrapper>
        {HOME_PAGES.map(({ id, to, name, color }) => id !== POPULATION && <Item key={to} to={to} $text={name} $color={color} />)}
      </Wrapper>
    </Container>
  );
};

DesktopMenu.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default DesktopMenu;
