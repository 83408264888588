import React, { useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { POPULATION, ELDERLY, DEMENTIA, DOCTOR, SCENARIO_HOME } from 'utils/routes';

import { Container } from './styles';

const Select = ({ label, items, index, animate }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const value = useMemo(
    () => ([POPULATION, ELDERLY, DEMENTIA, DOCTOR, SCENARIO_HOME].includes(pathname) ? pathname : ''),
    [pathname]
  );
  const onSelect = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.value) navigate(currentTarget.value);
    },
    [navigate]
  );

  return (
    <Container custom={index} animate={animate} value={value} onChange={onSelect}>
      <option value="" label={label} disabled>
        {label}
      </option>
      {items.map(({ to, name }) => (
        <option key={to} value={to} label={name} />
      ))}
    </Container>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  index: PropTypes.number.isRequired,
  animate: PropTypes.string.isRequired,
};

export default Select;
